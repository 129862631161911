export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Produtos</h2>
          <p>
            Nossos produtos são projetados para oferecer uma experiência de uso
            simplificada e eficiente. Com uma construção robusta, garantimos
            durabilidade e resistência em todas as situações de uso.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <img
                    src={d.icon}
                    alt={d.name}
                    style={{
                      width: 220,
                      height: 220,
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  ></img>
                  <div className="service-desc">
                    <h3>
                      {d.name}
                      <h5>{d.modelo}</h5>
                    </h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
